exports.components = {
  "component---src-data-pages-404-index-tsx": () => import("./../../../src/data/pages/404/index.tsx" /* webpackChunkName: "component---src-data-pages-404-index-tsx" */),
  "component---src-data-pages-acceso-profesionales-index-jsx": () => import("./../../../src/data/pages/acceso-profesionales/index.jsx" /* webpackChunkName: "component---src-data-pages-acceso-profesionales-index-jsx" */),
  "component---src-data-pages-aviso-legal-index-jsx": () => import("./../../../src/data/pages/aviso-legal/index.jsx" /* webpackChunkName: "component---src-data-pages-aviso-legal-index-jsx" */),
  "component---src-data-pages-contacto-index-jsx": () => import("./../../../src/data/pages/contacto/index.jsx" /* webpackChunkName: "component---src-data-pages-contacto-index-jsx" */),
  "component---src-data-pages-destacado-alivia-la-tos-seca-con-congestion-nasal-index-jsx": () => import("./../../../src/data/pages/destacado/alivia-la-tos-seca-con-congestion-nasal/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-alivia-la-tos-seca-con-congestion-nasal-index-jsx" */),
  "component---src-data-pages-destacado-alivia-la-tos-seca-en-los-peques-index-jsx": () => import("./../../../src/data/pages/destacado/alivia-la-tos-seca-en-los-peques/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-alivia-la-tos-seca-en-los-peques-index-jsx" */),
  "component---src-data-pages-destacado-alivio-de-los-mocos-y-la-congestion-index-jsx": () => import("./../../../src/data/pages/destacado/alivio-de-los-mocos-y-la-congestion/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-alivio-de-los-mocos-y-la-congestion-index-jsx" */),
  "component---src-data-pages-destacado-aprende-mas-sobre-su-crecimiento-index-jsx": () => import("./../../../src/data/pages/destacado/aprende-mas-sobre-su-crecimiento/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-aprende-mas-sobre-su-crecimiento-index-jsx" */),
  "component---src-data-pages-destacado-calma-su-tos-con-remedios-caseros-index-jsx": () => import("./../../../src/data/pages/destacado/calma-su-tos-con-remedios-caseros/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-calma-su-tos-con-remedios-caseros-index-jsx" */),
  "component---src-data-pages-destacado-como-actuar-ante-la-tos-en-ninos-index-jsx": () => import("./../../../src/data/pages/destacado/como-actuar-ante-la-tos-en-ninos/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-como-actuar-ante-la-tos-en-ninos-index-jsx" */),
  "component---src-data-pages-destacado-como-aliviar-la-tos-seca-de-los-ninos-index-jsx": () => import("./../../../src/data/pages/destacado/como-aliviar-la-tos-seca-de-los-ninos/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-como-aliviar-la-tos-seca-de-los-ninos-index-jsx" */),
  "component---src-data-pages-destacado-conoce-como-acabar-con-la-tos-seca-index-jsx": () => import("./../../../src/data/pages/destacado/conoce-como-acabar-con-la-tos-seca/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-conoce-como-acabar-con-la-tos-seca-index-jsx" */),
  "component---src-data-pages-destacado-descubre-como-aliviar-la-tos-con-mocos-index-jsx": () => import("./../../../src/data/pages/destacado/descubre-como-aliviar-la-tos-con-mocos/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-descubre-como-aliviar-la-tos-con-mocos-index-jsx" */),
  "component---src-data-pages-destacado-descubre-como-aliviar-la-tos-seca-0-index-jsx": () => import("./../../../src/data/pages/destacado/descubre-como-aliviar-la-tos-seca-0/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-descubre-como-aliviar-la-tos-seca-0-index-jsx" */),
  "component---src-data-pages-destacado-descubre-como-aliviar-la-tos-seca-index-jsx": () => import("./../../../src/data/pages/destacado/descubre-como-aliviar-la-tos-seca/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-descubre-como-aliviar-la-tos-seca-index-jsx" */),
  "component---src-data-pages-destacado-descubre-como-aliviar-tos-mocos-index-jsx": () => import("./../../../src/data/pages/destacado/descubre-como-aliviar-tos-mocos/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-descubre-como-aliviar-tos-mocos-index-jsx" */),
  "component---src-data-pages-destacado-descubre-la-gama-iniston-index-jsx": () => import("./../../../src/data/pages/destacado/descubre-la-gama-iniston/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-descubre-la-gama-iniston-index-jsx" */),
  "component---src-data-pages-destacado-el-jarabe-expectorante-para-ninos-index-jsx": () => import("./../../../src/data/pages/destacado/el-jarabe-expectorante-para-ninos/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-el-jarabe-expectorante-para-ninos-index-jsx" */),
  "component---src-data-pages-destacado-hay-un-iniston-para-ti-descubrelo-index-jsx": () => import("./../../../src/data/pages/destacado/hay-un-iniston-para-ti-descubrelo/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-hay-un-iniston-para-ti-descubrelo-index-jsx" */),
  "component---src-data-pages-destacado-por-que-tenemos-tos-nocturna-index-jsx": () => import("./../../../src/data/pages/destacado/por-que-tenemos-tos-nocturna/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-por-que-tenemos-tos-nocturna-index-jsx" */),
  "component---src-data-pages-destacado-que-tipo-de-tos-tiene-tu-pequeno-index-jsx": () => import("./../../../src/data/pages/destacado/que-tipo-de-tos-tiene-tu-pequeno/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-que-tipo-de-tos-tiene-tu-pequeno-index-jsx" */),
  "component---src-data-pages-destacado-reconoce-la-tos-con-mocos-de-los-ninos-index-jsx": () => import("./../../../src/data/pages/destacado/reconoce-la-tos-con-mocos-de-los-ninos/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-reconoce-la-tos-con-mocos-de-los-ninos-index-jsx" */),
  "component---src-data-pages-destacado-remedios-contra-la-tos-con-mocos-index-jsx": () => import("./../../../src/data/pages/destacado/remedios-contra-la-tos-con-mocos/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-remedios-contra-la-tos-con-mocos-index-jsx" */),
  "component---src-data-pages-destacado-remedios-contra-la-tos-index-jsx": () => import("./../../../src/data/pages/destacado/remedios-contra-la-tos/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-remedios-contra-la-tos-index-jsx" */),
  "component---src-data-pages-destacado-sabes-que-tipo-de-tos-tienes-index-jsx": () => import("./../../../src/data/pages/destacado/sabes-que-tipo-de-tos-tienes/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-sabes-que-tipo-de-tos-tienes-index-jsx" */),
  "component---src-data-pages-destacado-trucos-tradicionales-que-calman-tu-tos-index-jsx": () => import("./../../../src/data/pages/destacado/trucos-tradicionales-que-calman-tu-tos/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-trucos-tradicionales-que-calman-tu-tos-index-jsx" */),
  "component---src-data-pages-destacado-y-para-los-mas-pequenos-index-jsx": () => import("./../../../src/data/pages/destacado/y-para-los-mas-pequenos/index.jsx" /* webpackChunkName: "component---src-data-pages-destacado-y-para-los-mas-pequenos-index-jsx" */),
  "component---src-data-pages-dibujos-para-colorear-index-jsx": () => import("./../../../src/data/pages/dibujos-para-colorear/index.jsx" /* webpackChunkName: "component---src-data-pages-dibujos-para-colorear-index-jsx" */),
  "component---src-data-pages-index-jsx": () => import("./../../../src/data/pages/index.jsx" /* webpackChunkName: "component---src-data-pages-index-jsx" */),
  "component---src-data-pages-jarabe-inistolin-pediatrico-antitusivo-index-jsx": () => import("./../../../src/data/pages/jarabe-inistolin/pediatrico-antitusivo/index.jsx" /* webpackChunkName: "component---src-data-pages-jarabe-inistolin-pediatrico-antitusivo-index-jsx" */),
  "component---src-data-pages-jarabe-inistolin-pediatrico-expectorante-index-jsx": () => import("./../../../src/data/pages/jarabe-inistolin/pediatrico-expectorante/index.jsx" /* webpackChunkName: "component---src-data-pages-jarabe-inistolin-pediatrico-expectorante-index-jsx" */),
  "component---src-data-pages-jarabe-iniston-antitusivo-descongestivo-index-jsx": () => import("./../../../src/data/pages/jarabe-iniston/antitusivo-descongestivo/index.jsx" /* webpackChunkName: "component---src-data-pages-jarabe-iniston-antitusivo-descongestivo-index-jsx" */),
  "component---src-data-pages-jarabe-iniston-antitusivo-index-jsx": () => import("./../../../src/data/pages/jarabe-iniston/antitusivo/index.jsx" /* webpackChunkName: "component---src-data-pages-jarabe-iniston-antitusivo-index-jsx" */),
  "component---src-data-pages-jarabe-iniston-expectorante-descongestivo-index-jsx": () => import("./../../../src/data/pages/jarabe-iniston/expectorante-descongestivo/index.jsx" /* webpackChunkName: "component---src-data-pages-jarabe-iniston-expectorante-descongestivo-index-jsx" */),
  "component---src-data-pages-jarabe-iniston-expectorante-index-jsx": () => import("./../../../src/data/pages/jarabe-iniston/expectorante/index.jsx" /* webpackChunkName: "component---src-data-pages-jarabe-iniston-expectorante-index-jsx" */),
  "component---src-data-pages-jarabe-iniston-index-jsx": () => import("./../../../src/data/pages/jarabe-iniston/index.jsx" /* webpackChunkName: "component---src-data-pages-jarabe-iniston-index-jsx" */),
  "component---src-data-pages-jarabe-iniston-mucolitico-index-jsx": () => import("./../../../src/data/pages/jarabe-iniston/mucolitico/index.jsx" /* webpackChunkName: "component---src-data-pages-jarabe-iniston-mucolitico-index-jsx" */),
  "component---src-data-pages-la-tos-como-aliviar-la-tos-index-jsx": () => import("./../../../src/data/pages/la-tos/como-aliviar-la-tos/index.jsx" /* webpackChunkName: "component---src-data-pages-la-tos-como-aliviar-la-tos-index-jsx" */),
  "component---src-data-pages-la-tos-cuando-tienes-tos-alergia-index-jsx": () => import("./../../../src/data/pages/la-tos/cuando-tienes-tos/alergia/index.jsx" /* webpackChunkName: "component---src-data-pages-la-tos-cuando-tienes-tos-alergia-index-jsx" */),
  "component---src-data-pages-la-tos-cuando-tienes-tos-con-gripe-resfriado-index-jsx": () => import("./../../../src/data/pages/la-tos/cuando-tienes-tos/con-gripe-resfriado/index.jsx" /* webpackChunkName: "component---src-data-pages-la-tos-cuando-tienes-tos-con-gripe-resfriado-index-jsx" */),
  "component---src-data-pages-la-tos-cuando-tienes-tos-fumador-index-jsx": () => import("./../../../src/data/pages/la-tos/cuando-tienes-tos/fumador/index.jsx" /* webpackChunkName: "component---src-data-pages-la-tos-cuando-tienes-tos-fumador-index-jsx" */),
  "component---src-data-pages-la-tos-cuando-tienes-tos-nocturna-index-jsx": () => import("./../../../src/data/pages/la-tos/cuando-tienes-tos/nocturna/index.jsx" /* webpackChunkName: "component---src-data-pages-la-tos-cuando-tienes-tos-nocturna-index-jsx" */),
  "component---src-data-pages-la-tos-cuando-tienes-tos-verano-index-jsx": () => import("./../../../src/data/pages/la-tos/cuando-tienes-tos/verano/index.jsx" /* webpackChunkName: "component---src-data-pages-la-tos-cuando-tienes-tos-verano-index-jsx" */),
  "component---src-data-pages-la-tos-index-jsx": () => import("./../../../src/data/pages/la-tos/index.jsx" /* webpackChunkName: "component---src-data-pages-la-tos-index-jsx" */),
  "component---src-data-pages-la-tos-que-es-la-tos-funcionamiento-index-jsx": () => import("./../../../src/data/pages/la-tos/que-es-la-tos/funcionamiento/index.jsx" /* webpackChunkName: "component---src-data-pages-la-tos-que-es-la-tos-funcionamiento-index-jsx" */),
  "component---src-data-pages-la-tos-que-es-la-tos-index-jsx": () => import("./../../../src/data/pages/la-tos/que-es-la-tos/index.jsx" /* webpackChunkName: "component---src-data-pages-la-tos-que-es-la-tos-index-jsx" */),
  "component---src-data-pages-la-tos-que-es-la-tos-vivencia-index-jsx": () => import("./../../../src/data/pages/la-tos/que-es-la-tos/vivencia/index.jsx" /* webpackChunkName: "component---src-data-pages-la-tos-que-es-la-tos-vivencia-index-jsx" */),
  "component---src-data-pages-la-tos-tipos-de-tos-con-mocos-index-jsx": () => import("./../../../src/data/pages/la-tos/tipos-de-tos/con-mocos/index.jsx" /* webpackChunkName: "component---src-data-pages-la-tos-tipos-de-tos-con-mocos-index-jsx" */),
  "component---src-data-pages-la-tos-tipos-de-tos-seca-index-jsx": () => import("./../../../src/data/pages/la-tos/tipos-de-tos/seca/index.jsx" /* webpackChunkName: "component---src-data-pages-la-tos-tipos-de-tos-seca-index-jsx" */),
  "component---src-data-pages-politica-de-cookies-index-jsx": () => import("./../../../src/data/pages/politica-de-cookies/index.jsx" /* webpackChunkName: "component---src-data-pages-politica-de-cookies-index-jsx" */),
  "component---src-data-pages-politica-de-privacidad-index-jsx": () => import("./../../../src/data/pages/politica-de-privacidad/index.jsx" /* webpackChunkName: "component---src-data-pages-politica-de-privacidad-index-jsx" */),
  "component---src-data-pages-preguntas-frecuentes-index-jsx": () => import("./../../../src/data/pages/preguntas-frecuentes/index.jsx" /* webpackChunkName: "component---src-data-pages-preguntas-frecuentes-index-jsx" */),
  "component---src-data-pages-remedios-caseros-y-naturales-index-jsx": () => import("./../../../src/data/pages/remedios/caseros-y-naturales/index.jsx" /* webpackChunkName: "component---src-data-pages-remedios-caseros-y-naturales-index-jsx" */),
  "component---src-data-pages-remedios-contra-la-tos-mocos-index-jsx": () => import("./../../../src/data/pages/remedios/contra-la-tos/mocos/index.jsx" /* webpackChunkName: "component---src-data-pages-remedios-contra-la-tos-mocos-index-jsx" */),
  "component---src-data-pages-remedios-contra-la-tos-seca-index-jsx": () => import("./../../../src/data/pages/remedios/contra-la-tos/seca/index.jsx" /* webpackChunkName: "component---src-data-pages-remedios-contra-la-tos-seca-index-jsx" */),
  "component---src-data-pages-remedios-index-jsx": () => import("./../../../src/data/pages/remedios/index.jsx" /* webpackChunkName: "component---src-data-pages-remedios-index-jsx" */),
  "component---src-data-pages-remedios-tos-nocturna-index-jsx": () => import("./../../../src/data/pages/remedios/tos-nocturna/index.jsx" /* webpackChunkName: "component---src-data-pages-remedios-tos-nocturna-index-jsx" */),
  "component---src-data-pages-tos-ninos-causas-index-jsx": () => import("./../../../src/data/pages/tos-ninos/causas/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-causas-index-jsx" */),
  "component---src-data-pages-tos-ninos-desarrollo-infantil-10-anos-index-jsx": () => import("./../../../src/data/pages/tos-ninos/desarrollo-infantil/10-anos/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-desarrollo-infantil-10-anos-index-jsx" */),
  "component---src-data-pages-tos-ninos-desarrollo-infantil-11-anos-index-jsx": () => import("./../../../src/data/pages/tos-ninos/desarrollo-infantil/11-anos/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-desarrollo-infantil-11-anos-index-jsx" */),
  "component---src-data-pages-tos-ninos-desarrollo-infantil-12-anos-index-jsx": () => import("./../../../src/data/pages/tos-ninos/desarrollo-infantil/12-anos/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-desarrollo-infantil-12-anos-index-jsx" */),
  "component---src-data-pages-tos-ninos-desarrollo-infantil-7-anos-index-jsx": () => import("./../../../src/data/pages/tos-ninos/desarrollo-infantil/7-anos/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-desarrollo-infantil-7-anos-index-jsx" */),
  "component---src-data-pages-tos-ninos-desarrollo-infantil-8-anos-index-jsx": () => import("./../../../src/data/pages/tos-ninos/desarrollo-infantil/8-anos/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-desarrollo-infantil-8-anos-index-jsx" */),
  "component---src-data-pages-tos-ninos-desarrollo-infantil-9-anos-index-jsx": () => import("./../../../src/data/pages/tos-ninos/desarrollo-infantil/9-anos/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-desarrollo-infantil-9-anos-index-jsx" */),
  "component---src-data-pages-tos-ninos-index-jsx": () => import("./../../../src/data/pages/tos-ninos/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-index-jsx" */),
  "component---src-data-pages-tos-ninos-jarabe-inistolin-pediatrico-antitusivo-index-jsx": () => import("./../../../src/data/pages/tos-ninos/jarabe-inistolin/pediatrico-antitusivo/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-jarabe-inistolin-pediatrico-antitusivo-index-jsx" */),
  "component---src-data-pages-tos-ninos-jarabe-inistolin-pediatrico-expectorante-index-jsx": () => import("./../../../src/data/pages/tos-ninos/jarabe-inistolin/pediatrico-expectorante/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-jarabe-inistolin-pediatrico-expectorante-index-jsx" */),
  "component---src-data-pages-tos-ninos-remedios-index-jsx": () => import("./../../../src/data/pages/tos-ninos/remedios/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-remedios-index-jsx" */),
  "component---src-data-pages-tos-ninos-tipos-de-tos-ninos-irritativa-index-jsx": () => import("./../../../src/data/pages/tos-ninos/tipos-de-tos-ninos/irritativa/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-tipos-de-tos-ninos-irritativa-index-jsx" */),
  "component---src-data-pages-tos-ninos-tipos-de-tos-ninos-productiva-con-flemas-index-jsx": () => import("./../../../src/data/pages/tos-ninos/tipos-de-tos-ninos/productiva-con-flemas/index.jsx" /* webpackChunkName: "component---src-data-pages-tos-ninos-tipos-de-tos-ninos-productiva-con-flemas-index-jsx" */)
}

